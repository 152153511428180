import styled from "@emotion/styled";
import React from "react";

const Button = ({ styleClass, children, ...props }) => {
  return (
    <Btn type="button" className={styleClass} {...props}>
      {children}
    </Btn>
  );
};

export default Button;

const Btn = styled.button`
  width: 100%;
  height: 50px;
  background: #4400d3;
  border: 1px solid #4400d3;
  border-radius: 7px;

  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #ffffff;

  &.line {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    color: #1e1e1e;
  }

  &:disabled {
    background: #b7bdd9;
    border: 1px solid #b7bdd9;
    color: #e8e8e8;
  }

  @media (max-width: 768px) {
    height: 44px;
  }
`;
