import React from "react";
import { Route } from "react-router-dom";
import { PrivatePage } from "../pages";
import auth from "../utils/auth";

const PrivateRouter = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.getToken() !== null &&
        auth.getToken() !== "null" &&
        auth.getToken() !== undefined &&
        auth.getToken() !== "undefined" ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <PrivatePage {...props} />
        )
      }
    />
  );
};

export default PrivateRouter;
