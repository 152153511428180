import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  AppLoginApple,
  AppLoginGoogle,
  AppLoginKakao,
  AppLoginNaver,
  Bookmark,
  Certification,
  ContentDetail,
  Contents,
  EpisodeDetail,
  FindedId,
  FindId,
  FindPw,
  Info,
  Join,
  Login,
  Mypage,
  ProductDetail,
  Root,
  Editinfo,
  InfoCheck,
  Like,
  EditPw,
  EditId,
  EditPhone,
  EditAdress,
  EditBank,
  Notice,
  NoticeDetail,
  Alarm,
  Service,
  Private,
  Purchase,
  PurchaseResult,
  Cart,
  Coupon,
  LiveCommerce,
  Setting,
  OrderStatus,
  OrderDetail,
  Loading,
  FindPwId,
  Qna,
  QnaDetail,
  Event,
  EventDetail,
} from "../pages";
import PrivateRouter from "../component/PrivateRouter";
import { api } from "../utils/api";
import { SWRConfig } from "swr";
import LoginRouter from "../component/LoginRouter.";
import { useEffect } from "react";

const App = () => {
  const swrConfig = {
    fetcher(url, params) {
      return api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };

  useEffect(() => {
    window.localStorage.removeItem("appstart");
  }, []);

  return (
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <Switch>
          {/* 메인 */}
          <Route exact path="/" component={Root} />
          {/* 메인 */}
          <Route exact path="/loading" component={Loading} />
          {/* 로그인 */}
          <Route exact path="/login" component={Login} />
          {/* 회원가입 */}
          <Route exact path="/join" component={Join} />
          <Route exact path="/certification" component={Certification} />
          <Route exact path="/info" component={Info} />
          {/* sns로그인 */}
          <Route exact path="/app/login/kakao" component={AppLoginKakao} />
          <Route exact path="/app/login/naver" component={AppLoginNaver} />
          <Route exact path="/app/login/google" component={AppLoginGoogle} />
          <Route exact path="/app/login/apple" component={AppLoginApple} />
          {/* 아이디 찾기 */}
          <Route exact path="/find_id" component={FindId} />
          <Route exact path="/finded_id" component={FindedId} />
          {/* 비밀번호 찾기 */}
          <Route exact path="/find_pw" component={FindPwId} />
          <Route exact path="/find_pw/:id" component={FindPw} />
          {/* 장바구니 */}
          <PrivateRouter exact path="/cart" component={Cart} />
          {/* 알림 */}
          <PrivateRouter exact path="/alarm" component={Alarm} />
          {/* 콘텐츠 */}
          <Route exact path="/content" component={Contents} />
          <Route
            exact
            path="/content/detail/:contentId"
            component={ContentDetail}
          />
          <Route
            exact
            path="/content/detail/:contentId/:episodeId"
            component={EpisodeDetail}
          />
          {/* 라이브커머스 */}
          <Route exact path="/livecommerce" component={LiveCommerce} />
          {/* 즐겨찾기 */}
          <PrivateRouter exact path="/content/bookmark" component={Bookmark} />
          {/* 찜 */}
          <LoginRouter exact path="/like" component={Like} />
          {/* 마이페이지 */}
          <LoginRouter exact path="/mypage" component={Mypage} />
          <PrivateRouter exact path="/coupon" component={Coupon} />
          <PrivateRouter exact path="/editinfo" component={Editinfo} />
          {/* 주문현황 */}
          <PrivateRouter exact path="/order" component={OrderStatus} />
          <PrivateRouter
            exact
            path="/order/detail/:id"
            component={OrderDetail}
          />
          {/* 공지사항 */}
          <Route exact path="/notice" component={Notice} />
          <Route exact path="/notice/detail/:id" component={NoticeDetail} />
          {/* 1:1 문의 */}
          <Route exact path="/qna" component={Qna} />
          <Route exact path="/qna/detail/:id" component={QnaDetail} />
          {/* 서비스 이용약관/개인정보 처리방침 */}
          <Route exact path="/service" component={Service} />
          <Route exact path="/private" component={Private} />
          {/* 알림설정 */}
          <PrivateRouter exact path="/setting" component={Setting} />
          {/* 계정확인 */}
          <PrivateRouter exact path="/infocheck" component={InfoCheck} />
          {/* 아이디/비밀번호 변경/휴대폰번호/배송지관리/환불계좌 */}
          <PrivateRouter exact path="/edit_id" component={EditId} />
          <PrivateRouter exact path="/edit_pw" component={EditPw} />
          <PrivateRouter exact path="/edit_phone" component={EditPhone} />
          <PrivateRouter exact path="/edit_adress" component={EditAdress} />
          <PrivateRouter exact path="/edit_bank" component={EditBank} />
          {/* 상품상세 */}
          <Route exact path="/product/:id" component={ProductDetail} />
          {/* 상품구매 */}
          <PrivateRouter exact path="/purchase" component={Purchase} />
          <PrivateRouter exact path="/purchase/cart" component={Purchase} />
          <PrivateRouter
            exact
            path="/purchase/result/:id"
            component={PurchaseResult}
          />
          {/* 이벤트 */}
          <PrivateRouter exact path="/event" component={Event} />
          <PrivateRouter
            exact
            path="/event/detail/:id"
            component={EventDetail}
          />
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

export default App;
