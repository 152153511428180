import styled from "@emotion/styled";
import React from "react";
import { useHistory } from "react-router";
import Button from "./Button";

const LoginAlert = ({ onClick }) => {
  const history = useHistory();

  return (
    <Wra>
      <PopupWra>
        <Title>로그인 필요</Title>
        <Text>로그인이 필요한 페이지입니다.</Text>
        <ButtonArea>
          <Button styleClass="line" onClick={onClick}>
            취소
          </Button>
          <Button onClick={() => history.push("/login")}>로그인</Button>
        </ButtonArea>
      </PopupWra>
      <PopupBg onClick={onClick} />
    </Wra>
  );
};

export default LoginAlert;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    width: calc(50% - 5px);
  }

  @media (max-width: 768px) {
    & > button {
      width: calc(50% - 4px);
    }
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.4px;
  color: #616161;
  text-align: center;
  margin: 12px 0 30px;

  @media (max-width: 768px) {
    font-size: 13px;
    margin: 10px 0 28px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;
  color: #000000;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const PopupWra = styled.div`
  position: absolute;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: #ffffff;
  border-radius: 15px;
  padding: 30px;

  @media (max-width: 768px) {
    width: 100%;
    bottom: 0;
    top: auto;
    left: 0;
    transform: none;
    border-radius: 15px 15px 0 0;
    padding: 26px 20px 20px;
  }
`;

const PopupBg = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const Wra = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 100;
`;
