import React from "react";
import ReactDOM from "react-dom";
import App from "./client/App";

import "./styles/reset.css";
import "./styles/index.css";
import "./styles/font.css";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

ReactDOM.render(<App />, document.getElementById("root"));
