import React, { useState, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import auth from "../utils/auth";
import LoginAlert from "./LoginAlert";

const LoginRouter = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (!auth.getToken()) setLogin(true);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Component {...props} />
          {login && <LoginAlert onClick={() => history.goBack()} />}
        </>
      )}
    />
  );
};

export default LoginRouter;
