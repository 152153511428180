import loadable from "@loadable/component";

//메인
export const Root = loadable(() => import("./Root"));
//로딩
export const Loading = loadable(() => import("./Loading"));

// 진입불가
export const PrivatePage = loadable(() => import("./PrivatePage"));

//로그인
export const Login = loadable(() => import("./user/Login"));

//회원가입
export const Join = loadable(() => import("./user/Join"));
export const Certification = loadable(() => import("./user/Certification"));
export const Info = loadable(() => import("./user/Info"));

//아이디 찾기
export const FindId = loadable(() => import("./user/FindId"));
export const FindedId = loadable(() => import("./user/FindedId"));

//비밀번호 찾기
export const FindPw = loadable(() => import("./user/FindPw"));
export const FindPwId = loadable(() => import("./user/FindPwId"));

// sns로그인
export const AppLoginKakao = loadable(() => import("./user/AppLoginKakao"));
export const AppLoginNaver = loadable(() => import("./user/AppLoginNaver"));
export const AppLoginGoogle = loadable(() => import("./user/AppLoginGoogle"));
export const AppLoginApple = loadable(() => import("./user/AppLoginApple"));

//장바구니
export const Cart = loadable(() => import("./header/Cart"));
//알림
export const Alarm = loadable(() => import("./header/Alarm"));

//콘텐츠
export const Contents = loadable(() => import("./contents/Contents"));
export const ContentDetail = loadable(() => import("./contents/Detail"));
export const EpisodeDetail = loadable(() => import("./contents/EpisodeDetail"));

//즐겨찾기, 관심상품
export const Bookmark = loadable(() => import("./contents/Bookmark"));
export const Like = loadable(() => import("./contents/Like"));
//라이브커머스
export const LiveCommerce = loadable(() => import("./contents/LiveCommerce"));

// 마이페이지
export const Mypage = loadable(() => import("./mypage/Mypage"));
// 마이페이지_주문현황/주문상세
export const OrderStatus = loadable(() => import("./mypage/OrderStatus"));
export const OrderDetail = loadable(() => import("./mypage/OrderDetail"));

// 마이페이지_공지사항
export const Notice = loadable(() => import("./mypage/Notice"));
export const NoticeDetail = loadable(() => import("./mypage/NoticeDetail"));

// 마이페이지_1:1 문의
export const Qna = loadable(() => import("./mypage/Qna"));
export const QnaDetail = loadable(() => import("./mypage/QnaDetail"));

// 마이페이지_서비스 이용약관/개인정보 처리방침
export const Service = loadable(() => import("./mypage/Service"));
export const Private = loadable(() => import("./mypage/Private"));

// 마이페이지_알림설정
export const Setting = loadable(() => import("./mypage/Setting"));
// 마이페이지_쿠폰함
export const Coupon = loadable(() => import("./mypage/Coupon"));
// 마이페이지_내 정보 수정
export const Editinfo = loadable(() => import("./mypage/Editinfo"));

// 마이페이지_계정확인
export const InfoCheck = loadable(() => import("./mypage/InfoCheck"));

// 마이페이지_아이디,비밀번호,휴대폰번호/배송지/환불계좌 변경
export const EditId = loadable(() => import("./mypage/EditId"));
export const EditPw = loadable(() => import("./mypage/EditPw"));
export const EditPhone = loadable(() => import("./mypage/EditPhone"));
export const EditAdress = loadable(() => import("./mypage/EditAdress"));
export const EditBank = loadable(() => import("./mypage/EditBank"));

// 상품상세
export const ProductDetail = loadable(() => import("./product/Detail"));

// 상품 결제
export const Purchase = loadable(() => import("./product/Purchase"));
export const PurchaseResult = loadable(() => import("./product/Result"));

// 이벤트
export const Event = loadable(() => import("./event/Event"));
export const EventDetail = loadable(() => import("./event/EventDetail"));
